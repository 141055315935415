<template>
    <router-link
        :to="facebookAdvertisingRedirection(dealerId)">
        {{ dealerName }}
    </router-link>
</template>

<script>
export default {
    name: 'DealerRoutingLink',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dealerId: this.item?.dealer?.id,
            dealerName: this.item?.dealer?.name
        };
    },
    methods: {
        facebookAdvertisingRedirection(dealerId) {
            return {
                name: 'reports-facebook-advertising',
                params: {
                    dealer_id: dealerId
                }
            };
        },
    }
};
</script>
